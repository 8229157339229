<template>
    <div class="inner-section">
         <card>
            <template v-slot:searchHeaderTitle>
                <h4 class="card-title">{{$t('elearning_config.pay_grade_setup_new')}}</h4>
            </template>
            <template v-slot:searchBody>
                <b-row>
                    <b-col lg="6" sm="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_config.training_type')"
                        label-for="training_type"
                        >
                        <v-select name="training_type"
                        v-model="search.training_type_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= trainingTypeList
                        />
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_config.pay_grade_from')"
                        label-for="pay_grade_from"
                        >
                        <v-select name="pay_grade_from"
                        v-model="search.pay_grade_from"
                        label="text"
                        :reduce="item => item.value"
                        :options= gradeList
                        />
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_config.pay_grade_to')"
                        label-for="pay_grade_to"
                        >
                        <v-select name="pay_grade_to"
                        v-model="search.pay_grade_to"
                        label="text"
                        :reduce="item => item.value"
                        :options= payGradeToList
                        />
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-button size="sm" variant="primary" @click="searchData">
                            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </card>
        <body-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_config.pay_grade_setup_new_list')}}</h4>
            </template>
            <template v-slot:headerAction>
                <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-4><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
            </template>
            <template v-slot:body>
                <b-overlay :show="loadingState">
                    <b-row>
                        <b-col sm="12">
                        <div class="quick-filter-wrapper">
                            <div class="quick-filter-left">
                            <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                                <b-dropdown-form>
                                <div
                                    class="form-group"
                                    v-for="(field, index) in labelData"
                                    :key="index"
                                    >
                                    <b-form-checkbox
                                        :id="'checkbox-' + field.label"
                                        v-model="field.show"
                                        :name="'checkbox-' + field.label"
                                        value=1
                                        unchecked-value=0
                                    >
                                        {{ ($i18n.locale==='bn') ? field.label : field.label }}
                                    </b-form-checkbox>
                                </div>
                                </b-dropdown-form>
                            </b-dropdown>
                            </div>
                            <div class="quick-filter-right">
                            <b-form-group
                                :label="$t('menu.perpage')"
                                label-for="per-page-select"
                            >
                                <b-form-select
                                id="per-page-select"
                                v-model="search.limit"
                                :options="pageOptions"
                                size="sm"
                                ></b-form-select>
                            </b-form-group>
                            </div>
                        </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12" class="table-responsive">
                            <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                                    <template v-slot:cell(serial)="data">
                                        {{ $n(data.item.serial + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(pay_grade_to)="data">
                                    {{ $n(data.item.pay_grade_to) }}
                                    </template>
                                    <template v-slot:cell(pay_grade_from)="data">
                                    {{ $n(data.item.pay_grade_from) }}
                                    </template>
                                    <template v-slot:cell(amount)="data">
                                    <span class="capitalize">{{ $n(data.item.amount, { useGroping: false }) }}</span>
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <b-button v-b-modal.modal-5 variant="iq-bg-success mr-1" size="sm" @click="detailData(data.item)" class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')"><i class="ri-eye-line"></i></b-button>
                                        <b-button v-b-modal.modal-4 variant="btn-edit" size="sm" @click="edit(data.item)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>
                                        <b-button :variant="data.item.status === 2 ? ' btn-danger' : ' btn-success'" size="sm" class="action-btn status" :title="data.item.status === 2 ? $t('globalTrans.inactive') : $t('globalTrans.active')" @click="remove(data.item)">
                                        <i :class="data.item.status === 2  ? 'ri-toggle-line' : 'ri-toggle-fill'"></i>
                                        </b-button>
                                    </template>
                                </b-table>
                            </slot>
                            <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                                <b-pagination
                                v-model="pagination.currentPage"
                                :perPage="search.limit"
                                :total-rows="pagination.totalRows"
                                @input="searchData"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </body-card>
        <b-modal id="modal-4" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <!-- <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal> -->
        <b-modal id="modal-5" size="lg" :title="$t('elearning_config.pay_grade_setup_new') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :item="item"/>
        </b-modal>
    </div>
</template>
<script>

import Form from './Form'
import Details from './Details'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { payGradeSetupList, payGradeSetupToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Details
    },
    data () {
        return {
            sortBy: '',
            sortDirection: '',
            sortDesc: '',
            item: [],
            // id: [],
            search: {
                training_type_id: '',
                pay_grade_to: '',
                pay_grade_from: '',
                limit: 20
            },
            labelData: [
                { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
                { labels: 'elearning_config.training_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
                { labels: 'elearning_config.pay_grade_from', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '35%' } },
                { labels: 'elearning_config.pay_grade_to', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '35%' } },
                { labels: 'elearning_config.amount', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '35%' } },
                { labels: 'globalTrans.remarks', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '35%' } },
                { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '10%' } },
                { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 5, thStyle: { width: '15%' } }
            ],
            payGradeToList: [],
            actions: {
                edit: true,
                toogle: true,
                view: true
            }
        }
    },
    computed: {
        trainingTypeList: function () {
            return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        },
        gradeList: function () {
           return this.$store.state.commonObj.gradeList.filter(item => item.status === 0)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_config.pay_grade_setup_new') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.pay_grade_setup_new') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labelData = this.labelData
            const labels = labelData.map((item, index) => {
                return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
            })
            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'serial' },
                    { key: 'training_type_bn' },
                    { key: 'pay_grade_from' },
                    { key: 'pay_grade_to' },
                    { key: 'amount' },
                    { key: 'remarks_bn' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'serial' },
                    { key: 'training_type' },
                    { key: 'pay_grade_from' },
                    { key: 'pay_grade_to' },
                    { key: 'amount' },
                    { key: 'remarks' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        pageOptions () {
            return this.$store.state.commonObj.pageOptions
        }
    },
    async created () {
        this.loadData()
    },
    watch: {
        'search.pay_grade_from': function (newValue) {
            this.payGradeToList = this.getpayGradeTo(newValue)
        },
        'search.limit': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loadData()
            }
        }
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        detailData (item) {
            this.item = item
        },
        getpayGradeTo (gradeId) {
            const grade = this.gradeList
            if (gradeId) {
                return grade.filter(item => item.value > gradeId)
            }
            return grade
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(11, trainingElearningServiceBaseUrl, payGradeSetupToggleStatus, item)
        },
        officeList (orgId) {
          const office = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
          if (orgId) {
              return office.filter(item => item.org_id === orgId)
          }
          return office
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, payGradeSetupList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data, this.search.limit)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList(data) {
          const listData = data.map((item, index) => {
            const gradeFromObj = this.gradeList.find(doc => doc.value === parseInt(item.pay_grade_from))
            const gradeFromData = {}
            if(typeof gradeFromObj !== 'undefined') {
              gradeFromData.grade_from = gradeFromObj.text_en
              gradeFromData.grade_from_bn = gradeFromObj.text_bn
            } else {
              gradeFromData.grade_from = ''
              gradeFromData.grade_from_bn = ''
            }

            const gradeToObj = this.gradeList.find(doc => doc.value === parseInt(item.pay_grade_to))
            const gradeToData = {}
            if(typeof gradeToObj !== 'undefined') {
              gradeToData.grade_to = gradeToObj.text_en
              gradeToData.grade_to_bn = gradeToObj.text_bn
            } else {
              gradeToData.grade_to = ''
              gradeToData.grade_to_bn = ''
            }
            const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
            const trainingTypeData = {}
            if(typeof trainingTypeObj !== 'undefined') {
              trainingTypeData.training_type = trainingTypeObj.text_en
              trainingTypeData.training_type_bn = trainingTypeObj.text_bn
            } else {
              trainingTypeData.training_type = ''
              trainingTypeData.training_type_bn = ''
            }

            return Object.assign({}, item, { serial: index + 0}, gradeFromData, gradeToData, trainingTypeData)
          })
          return listData
        }
    }
}
</script>
